<template>
  <section>
    <b-card v-for="(tyProduct,tyIndex) in _products" :key="tyIndex">
      <MatchingProduct
          v-for="(product,pIndex) in tyProduct.products2"
          :key="pIndex"
          :tyProduct="tyProduct"
          :tyIndex="tyIndex"
          :product="product"
          :pIndex="pIndex"
      />
    </b-card>

  </section>

</template>

<script>
import {mapGetters} from "vuex";
import MatchingProduct from "@/views/trendyol/matching/MatchingProduct";

export default {
  name: "MatchingProducts",
  components: {MatchingProduct},
  computed: {
    ...mapGetters('tyProduct', ["_products", "_search", "_pagination"]),
  },
}
</script>

<style scoped>

</style>
