<template>
  <b-card>
    <b-row>
      <b-col md="2" class="imageContainer m-0 p-0">
        <div v-if="tyProduct.images">
          <div class="clearfix" v-if="tyProduct.images[0]" @click="showImages">
            <b-img :src="tyProduct.images[0].url" width="150"/>
          </div>
        </div>
      </b-col>
      <b-col md="2" class="imageContainer m-0 p-0">
        <div v-if="product.images">
          <div class="clearfix" v-if="product.images[0]" @click="showImages">
            <b-img :src="'https://api.kitapfirsatlari.com/' + product.images[0].url" width="150"/>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <a :href="tyProduct.url" target="_blank">
                  <feather-icon icon="ExternalLinkIcon"/>
                </a> {{ tyProduct.product_mp_id }}
                <b-badge variant="info" @click="productMatching">Ürün No Ekle</b-badge>
                <br>

                <br>
                <b-badge :variant="tyProduct.merchant_sku==product.barcode?'success':''"> {{
                    tyProduct.merchant_sku
                  }}
                </b-badge>
                <br>
                <b-badge v-if="tyProduct.detail">{{ JSON.parse(tyProduct.detail.detail).brandName }}</b-badge>
                <b-badge v-else>-</b-badge>
                <br>

                <b-badge v-if="tyProduct.detail">{{ JSON.parse(tyProduct.detail.detail).categoryName }}</b-badge>
                <b-badge v-else>-</b-badge>
                <br>

                <div style="word-wrap:break-word;"
                     :class="tyProduct.product_name==product.name?'bg-success text-white':'bg-secondary text-white'">
                  {{ tyProduct.product_name }}
                </div>

                <b-button-group>
                  <div v-if="tyProduct.buybox_list">
                    <a
                        v-for="(list,lIndex) in JSON.parse(tyProduct.buybox_list.list)"
                        :key="lIndex"
                        class="bg-info text-white mr-1"
                    >
                      <b>{{ list.merchantName }} {{ list.discountedPrice }} {{ list.currency }}</b>
                    </a>
                  </div>
                </b-button-group>

              </b-col>


            </b-row>
          </b-col>
          <b-col md="6">
            <a href="#" target="_blank">
              <feather-icon icon="ExternalLinkIcon"/>
            </a> {{ product.id }}
            <b-badge variant="info" @click="tyMatching">Ty SKU Ekle</b-badge>
            <br>

            <br>
            <b-badge :variant="tyProduct.merchant_sku==product.barcode?'success':''">
              {{ product.barcode }}
            </b-badge>
            <br>
            <b-badge v-if="product.brand">{{ product.brand.name }}</b-badge>
            <b-badge v-else-if="product.publishers[0]">
              {{ product.publishers[0].publisher_name }}
            </b-badge>
            <b-badge v-else>-</b-badge>
            <br>

            <b-badge v-if="product.categories[0]">{{ product.categories[0].name }}</b-badge>
            <b-badge v-else>-</b-badge>
            <br>

            <div style="word-wrap:break-word;"
                 :class="tyProduct.product_name==product.name?'bg-success text-white':'bg-secondary text-white'">
              {{ product.name }}
            </div>

            <b-button-group size="sm">
              <a
                  v-for="supplierProduct in product.supplier_products"
                  :key="supplierProduct.id"
                  class="bg-info text-white mr-1"
                  target="_blank"
                  :href="supplierProduct.url"
              >
                <b>
                  {{ supplier_name(supplierProduct.supplier_id) }} {{ supplierProduct.stock }} Ad. {{
                    supplierProduct.price
                  }}
                  {{ supplierProduct.currency }}
                </b>
              </a>
            </b-button-group>
          </b-col>
        </b-row>
        <b-row class="bg-light">
          <MatchingStoreProduct
              v-for="storeProduct in tyProduct.ty_store_products"
              :key="storeProduct.id"
              :storeProduct="storeProduct"
          />
        </b-row>
      </b-col>

      <b-col>
        <div>
          <b-form-checkbox v-model="product.matching" class="custom-control-success" name="check-button" switch/>
        </div>
        <b-badge class="mt-1" :variant="matching_status_color(product.pivot.status)">{{
            matching_status(product.pivot.status)
          }}
        </b-badge>
        <br>
        <b-button-group class="mt-1">
          <b-button @click="toSendStatus(2)" size="sm" variant="success"> Onayla</b-button>
          <b-button @click="toSendStatus(5)" size="sm" variant="warning"> Ertele</b-button>
          <b-button @click="toSendStatus(4)" size="sm" variant="danger"> Reddet</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import supplierMixins from "@/mixins/supplierMixins";
import matchingStatusMixins from "@/mixins/matchingStatusMixins";
import MatchingStoreProduct from "@/views/trendyol/matching/MatchingStoreProduct";

export default {
  name: "MatchingProduct",
  mixins: [supplierMixins, matchingStatusMixins],
  components: {MatchingStoreProduct},
  props: {
    tyIndex: {
      type: Number,
      required: true
    },
    tyProduct: {
      type: Object,
      required: true
    },
    pIndex: {
      type: Number,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('tyProduct', ["_matchingIds"]),
  },
  methods: {
    showImages() {

    },
    toSendStatus(statusId) {
      this.product.matching = false;
      let ids = [{
        ty_product_id: this.tyProduct.id,
        product_id: this.product.id,
        status: statusId
      }]
      this.$store.dispatch('tyProduct/setProductsPivotStatusData', ids);
    },
    productMatching() {
      this.$swal({
        title: 'Ürün No Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (productId) => {
          if (!productId) return null
          return this.$store.dispatch('product/addTyMatchingProduct', {productId, sku: this.tyProduct.merchant_sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
    tyMatching() {
      this.$swal({
        title: 'Pazaryeri SKU Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (sku) => {
          if (!sku) return null
          return this.$store.dispatch('product/addTyMatchingProduct', {productId: this.product.id, sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
