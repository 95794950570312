import { render, staticRenderFns } from "./MatchingProducts.vue?vue&type=template&id=390f0435&scoped=true&"
import script from "./MatchingProducts.vue?vue&type=script&lang=js&"
export * from "./MatchingProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "390f0435",
  null
  
)

export default component.exports