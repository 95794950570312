<template>
  <b-overlay :show="show" rounded="lg">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="2">
            <v-select
                v-model="_search.status"
                label="status"
                multiple
                :options="_matchStatus"
                :reduce="statu => statu.id"
                class="select-size-sm"
            />
          </b-col>
          <b-col md="4">
            <b-row>
              <b-col md="6">
                <b-form-input type="text" size="sm" v-model="_search.merchant_sku" placeholder="Pazaryeri SKU"/>
                <b-form-input type="text" size="sm" v-model="_search.product_mp_id" placeholder="Pazaryeri No ??"/>
                <b-form-input type="text" size="sm" v-model="_search.product_name" placeholder="Pazaryeri Adı"/>
              </b-col>
              <b-col md="6">
                <v-select
                    v-model="_search.suppliers"
                    label="name"
                    multiple
                    :options="_suppliers"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Tedarikçi"
                    :closeOnSelect="false"
                >
                </v-select>
                <v-select
                    v-model="_search.stores"
                    label="store_name"
                    multiple
                    :options="_stores"
                    :reduce="store => store.id"
                    class="select-size-sm"
                    placeholder="Pazaryeri"
                    :closeOnSelect="false"
                />
                <vue-autosuggest
                    :suggestions="filteredBrands"
                    :limit="limit"
                    :input-props="{id:'autosuggest__input',class:'form-control form-control-sm', placeholder:'Marka Seçiniz!'}"
                    @input="onInputChange"
                    @selected="selectHandler"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  </template>
                </vue-autosuggest>

                <b-badge v-for="(brand,key) in _search.brands" :key="brand.id"
                         @click="deleteBrand(key)">{{ brand.name }}
                </b-badge>


              </b-col>
            </b-row>

          </b-col>
          <b-col md="4">
            <b-row>
              <b-col md="6">
                <b-form-input type="text" size="sm" v-model="_search.product.barcode" placeholder="Ürün Barkod"/>
                <b-form-input type="text" size="sm" v-model="_search.product.id" placeholder="Ürün No"/>
                <b-form-input type="text" size="sm" v-model="_search.product.name" placeholder="Ürün Adı"/>
              </b-col>

              <b-col md="6">
                <b-form-input type="number" size="sm" v-model="_search.product.stock" placeholder="Stok (Büyük)"/>
                <b-form-input type="number" size="sm" v-model="_search.limit" placeholder="Limit Giriniz"/>

                <b-row>
                  <b-col class="demo-inline-spacing" style="margin-top: 0px">
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="all"
                    > Tümü
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="single"
                    > Tekli
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="multi"
                    > Çoklu
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <b-button-group size="sm">
              <b-button variant="primary" type="submit" @click="getAll">Getir</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>
      <b-row>
        <b-col md="2">
          <b-button-group size="sm">
            <b-button variant="info" :disabled="_products.length<1" @click="allProductsMatch(true)">Tümünü Seç
            </b-button>
            <b-button variant="info" :disabled="_products.length<1" @click="allProductsMatch(false)">Tümünü Kaldır
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <Pagination :pagination="_pagination"/>

    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import Pagination from "@/components/Pagination";
import {VueAutosuggest} from 'vue-autosuggest'

export default {
  name: "MatchingSearch",
  components: {vSelect, Pagination, VueAutosuggest},
  data: () => ({
    show: false,
    filteredBrands: [],
    limit: 20,
  }),
  computed: {
    ...mapGetters('tyProduct', ["_products", "_search", "_pagination"]),
    ...mapGetters('matchStatus', ['_matchStatus']),
    ...mapGetters('productBrand', ["_brands"]),
    ...mapGetters("supplier", ["_suppliers"]),
    _stores() {
      return this.$store.getters["store/_stores"].filter(item => item.marketplace_code == 'TY')
    },
  },
  methods: {
    getAll() {
      this.show = true
      this.$store.dispatch('tyProduct/getProducts')
          .then(res => {
            this.show = false;
          })
          .catch(() => {
            this.show = false;
          })
    },
    onInputChange(text) {
      if (text === '' || text === undefined) return
      const data = this._brands.filter(brand => brand.name.toLowerCase().indexOf(text.toLowerCase()) > -1).splice(0, this.limit);
      this.filteredBrands = [{data}]
    },
    selectHandler(option) {
      this.$store.state.tyProduct.search.brands.push(option.item);
    },
    deleteBrand(key) {
      this.$store.commit('tyProduct/deleteBrand', key)
    },
    allProductsMatch(match) {
      this._products.forEach(tyProduct => {
        tyProduct.products2.forEach(product => {
          product.matching = match
        })
      })
    }
  },
  created() {
    this._search.status = [0]
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
