import { render, staticRenderFns } from "./MatchingStoreProduct.vue?vue&type=template&id=396f3368&scoped=true&"
import script from "./MatchingStoreProduct.vue?vue&type=script&lang=js&"
export * from "./MatchingStoreProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396f3368",
  null
  
)

export default component.exports