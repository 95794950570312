<template>
  <b-col>
    <b-row class="border-secondary">
      <b-col md="6">
        Id: {{ storeProduct.id }} <br>
        Mağaza: {{ store_name(storeProduct.store_id) }} <br>
        Stok: {{ storeProduct.send_stock }} <br>
        Fiyat: {{ storeProduct.send_price }} <br>
        Hesaplanan: {{ storeProduct.calculated_price }} <br>
        Termin: {{ storeProduct.send_deadline }} <br>
      </b-col>
      <b-col md="6">
        TY Onay: {{ storeProduct.approved }} <br>
        TY Kilit: {{ storeProduct.locked }} <br>
        TY Satış: {{ storeProduct.onsale }} <br>
        TY Kara L.: {{ storeProduct.blacklisted }} <br>
        TY Kamp.: {{ storeProduct.hasActiveCampaign }} <br>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import storeMixins from "@/mixins/storeMixins";

export default {
  name: "MatchingStoreProduct",
  mixins: [storeMixins],
  props: {
    storeProduct: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
