<template>
  <section>
    <MatchingSearch/>
    <MatchingProducts/>

    <b-card>
      <b-button-group>
        <b-button  title="Seçili olanları eşleştir" @click="setSelectedMatching"
                  variant="success">Eşleştir
        </b-button>
      </b-button-group>
    </b-card>
  </section>
</template>

<script>
import MatchingSearch from "@/views/trendyol/matching/MatchingSearch";
import MatchingProducts from "@/views/trendyol/matching/MatchingProducts";
import {mapGetters} from "vuex";

export default {
  name: "MatchingHome",
  components: {MatchingSearch, MatchingProducts},
  computed: {
    ...mapGetters('tyProduct', ["_products"])
  },
  methods: {
    setSelectedMatching() {
      this.$store.dispatch('tyProduct/setProductsPivotStatus');
    }
  },
  created() {
    this.$store.dispatch('productBrand/getAll')
  }
}
</script>

<style scoped>

</style>
